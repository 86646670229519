(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:CompanyCallbackCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('CompanyCallbackCtrl', CompanyCallbackCtrl);

  function CompanyCallbackCtrl(CTAWidget, $state) {
    CTAWidget.open();
    $state.go('login');
  }
}());
